/* Base Preview Container */
.preview-container {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

/* Mobile Frame */
.mobile-frame {
  width: 375px;
  margin: 0 auto;
  background: #1a1a1a;
  border-radius: 40px;
  padding: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Dynamic Island */
.mobile-frame::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 25px;
  background: #000;
  border-radius: 20px;
  z-index: 2;
}

/* Content Area */
.mobile-frame .preview-content-wrapper {
  background: white;
  border-radius: 30px;
  height: 700px;
  overflow-y: auto;
  padding: 0;
}

/* Status Bar */
.mobile-frame .status-bar {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.mobile-frame .status-bar-content {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

/* Status Bar Icons */
.mobile-frame .status-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Battery Icon */
.mobile-frame .battery-icon {
  width: 22px;
  height: 10px;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 1px;
  position: relative;
  display: flex;
  align-items: center;
}

.mobile-frame .battery-icon::after {
  content: '';
  position: absolute;
  right: -3px;
  top: 3px;
  width: 2px;
  height: 4px;
  background: #000;
  border-radius: 0 1px 1px 0;
}

/* Battery Level - Full */
.mobile-frame .battery-level {
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 1px;
}

/* Signal Icon */
.mobile-frame .signal-icon {
  display: flex;
  align-items: flex-end;
  gap: 1px;
  height: 10px;
}

/* Email Header Section */
.mobile-frame .preview-content-wrapper .mb-4 {
  padding: 16px 16px 12px;
  margin: 0;
  border-bottom: 1px solid #eee;
  background: #fafafa;
}

.mobile-frame .preview-content-wrapper .text-sm {
  font-size: 13px;
  color: #666;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.mobile-frame .preview-content-wrapper .text-sm::before {
  content: attr(data-label);
  width: 45px;
  color: #999;
  font-weight: 500;
}

.mobile-frame .preview-content-wrapper .text-lg {
  font-size: 16px;
  line-height: 1.4;
  color: #000;
  margin-top: 12px;
  font-weight: 500;
  padding-left: 45px;
}

/* Email Content Area */
.mobile-frame .preview-content {
  padding: 20px 16px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

/* Home Indicator */
.mobile-frame .home-indicator {
  width: 120px;
  height: 4px;
  background: #000;
  border-radius: 100px;
  opacity: 0.2;
  margin: 8px auto;
}

/* Hide Scrollbar */
.mobile-frame .preview-content-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobile-frame .preview-content-wrapper::-webkit-scrollbar {
  display: none;
}

/* Desktop Frame */
.desktop-frame {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.desktop-frame .preview-content-wrapper {
  padding: 20px;
  min-height: 600px;
}

/* Desktop Email Header */
.desktop-frame .mb-4 {
  padding: 20px;
  margin: -20px -20px 20px;
  background: #fafafa;
  border-bottom: 1px solid #eee;
}

.desktop-frame .text-sm {
  font-size: 13px;
  color: #666;
  margin-bottom: 6px;
  display: flex;
  align-items: flex-start;
}

.desktop-frame .text-sm::before {
  content: attr(data-label);
  min-width: 45px;
  color: #999;
  font-weight: 500;
}

.desktop-frame .text-lg {
  font-size: 16px;
  line-height: 1.4;
  color: #000;
  margin-top: 12px;
  font-weight: 500;
  padding-left: 45px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

/* Desktop Content Area */
.desktop-frame .preview-content {
  padding: 0 20px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mobile-frame {
    width: 320px;
  }

  .mobile-frame .preview-content-wrapper {
    height: 600px;
  }
}

/* Scrollbar Styling */
.preview-content-wrapper::-webkit-scrollbar {
  width: 6px;
}

.preview-content-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.preview-content-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.preview-content-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom scrollbar for the preview */
.preview-content::-webkit-scrollbar {
  width: 8px;
}

.preview-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.preview-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.preview-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Animation for device switching */
.preview-container {
  transition: all 0.3s ease-in-out;
}

/* Custom Scroll Indicator */
.mobile-frame .preview-content-wrapper::after {
  content: '';
  position: fixed;
  right: 2px;
  top: 55px;
  bottom: 25px;
  width: 3px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s;
}

.mobile-frame .preview-content-wrapper:hover::after {
  opacity: 1;
}

/* Remove the wifi icon */
.mobile-frame .wifi-icon {
  display: none;
}
