@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dmserifdisplay/v1/NONjvnfMzCWTbM4Q7h1ZJnM.eot);
  src: url(https://fonts.gstatic.com/s/dmserifdisplay/v1/NONjvnfMzCWTbM4Q7h1ZJnM.eot?#iefix)
      format('embedded-opentype'),
    url(https://fonts.gstatic.com/s/dmserifdisplay/v1/NONjvnfMzCWTbM4Q7h1ZJnM.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/dmserifdisplay/v1/NONjvnfMzCWTbM4Q7h1ZJnM.woff) format('woff'),
    url(https://fonts.gstatic.com/s/dmserifdisplay/v1/NONjvnfMzCWTbM4Q7h1ZJnM.ttf)
      format('truetype'),
    url(https://fonts.gstatic.com/s/dmserifdisplay/v1/NONjvnfMzCWTbM4Q7h1ZJnM.svg#dm-serif-display)
      format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
}

@font-face {
  font-family: 'Tinos';
  src: local('Tinos'), url('./assets/Tinos/Tinos-Regular.ttf') format('ttf'),
    url('./assets/Tinos/Tinos-BoldItalic.ttf') format('ttf');
}

h1,
h2,
h3,
h4,
h6,
span,
p {
  @apply font-manrope;
}

.collapse-box {
  border: none !important;
  background-color: white !important;
}

.collapse-item {
  border: none !important;
  border-bottom: 1px #ccc solid !important;
  background-color: white !important;
  padding-top: 6px;
}

/* speak to clint page  */
.bg-img {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url('images/bg-img-1.png');
}

/* subscripe page */
.bg-img-5 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url('images/mask.png');
}

/* contact-us page  */
.bg-img-3 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url('images/bg-img-3.png');
}

/* client speaks arrow  */
.client-btm-icon::after {
  content: url('images/icons/arrow.png');
  display: block;
  text-align: center;
}
.loan-table-row {
  background-color: rgb(239, 239, 239);
  pointer-events: none;
}
.collapse-icon {
  left: 2px;
  top: 7px;
}
.card-box:hover {
  border: blue;
  border-radius: 2px;
  box-shadow: #ccc;
}
#team-card:hover #move {
  animation: mymove 1s;
  animation-fill-mode: forwards;
}
.primary-color {
  fill: #2623cc;
}
@media only screen and (max-width: 1024px) {
  #long-text {
    display: none;
  }
  #short-text {
    display: inline-block;
  }
}
@media only screen and (max-width: 1024px) {
  #md-button {
    display: none;
  }
  #sm-button {
    display: inline-block;
  }
}

@keyframes mymove {
  to {
    bottom: 2px;
  }
  to {
    left: 2px;
  }
}
.img:hover {
  transform: scale(1.5);
}
.nav-bar {
  @apply flex items-center justify-between flex-wrap bg-white px-4 py-3 fixed w-full;
  z-index: 200;
}
.nav-btn {
  @apply inline-block ml-4 text-sm px-6 py-3 leading-none border rounded hover:bg-primary mt-4 lg:mt-0;
}

.apply-for-loan {
  @apply text-base text-gray-600;
}

.collapse-panel {
  @apply hover:bg-light-blue-1 focus:bg-light-blue-1 font-bold;
}

.plus-and-minus {
  @apply border border-gray-500 rounded-full p-0.5 px-1;
}

.tab-items {
  @apply m-0 p-0 bg-blue-200 rounded-full w-4 h-4 grid place-items-center;
}

.tab-items-lg {
  @apply flex flex-col items-center justify-center mr-5;
}

.access-loans {
  @apply md:leading-relaxed absolute top-56 md:static text-white font-bold w-5/6 md:text-black text-center md:text-left text-3xl md:text-5xl md:pt-6 pb-6 px-6;
}

.auth-input {
  @apply w-full bg-white text-gray-900 mt-2 p-3 rounded-lg border-gray-300 border focus:outline-none focus:border-gray-600;
}

.login-control {
  @apply mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto  text-gray-900;
}

.dashboard-main {
  @apply bg-gray-100 flex;
}

.dashboard-y {
  @apply w-full flex flex-col h-screen;
}
.dashboard-x {
  @apply w-full overflow-x-hidden border-t flex flex-col;
}

.nav-menu {
  @apply z-10 w-12 h-12 rounded-full overflow-hidden border-4 border-gray-400 focus:border-gray-300;
}

.nav-sm-link {
  @apply block px-4 py-2 text-black;
}

.nav-link-md {
  @apply flex items-center text-black opacity-75 hover:opacity-100 py-2 border-b text-lg;
}
.mono-link-btn {
  @apply text-primary hover:text-primary-200 underline;
}
.mono-btn {
  @apply bg-primary ml-4 text-base px-8 py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;
}
.app-btn {
  @apply bg-primary ml-4 text-base px-8 py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;
}

.btn-primary {
  @apply outline-none text-lg px-5 rounded-md py-3 text-primary bg-pastel font-semibold;
}
.btn-primary-200 {
  @apply outline-none text-lg px-5 rounded-md py-3 bg-primary text-white font-bold;
}
.btn-white {
  @apply outline-none text-lg px-5 rounded-md py-3 text-gray-500 border font-bold;
}

.form-control {
  @apply w-full appearance-none bg-transparent border-2 mr-3 py-3 px-3 mt-1 focus:outline-none  border-gray-200 rounded-md;
}

.card-box {
  @apply border border-gray-400 flex justify-between rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 h-36 px-4 pt-8;
}

.nav-mini {
  @apply duration-300 md:transition-none max-h-0 md:max-h-full overflow-y-hidden flex flex-col;
}

.admin-side-menu {
  @apply relative bg-white h-screen hidden sm:block shadow-xl;
}

.side-menu-link {
  @apply flex items-center py-4 pl-6 text-black hover:bg-gray-200;
}
.ant-modal-content {
  border-radius: 20px !important;
}
.custom-steps .ant-steps-item-container::before {
  display: none;
}

.preview-modal .ant-modal-header {
  background-color: #2623cc;
  border-radius: 20px 20px 0px 0px;
}

/* ReceiptPage.css */

@media print {
  /* Hide unnecessary elements */
  .no-print,
  .print-hidden {
    display: none;
  }

  /*  styles for the first page when printing */
  @page :first {
    margin-top: 50px;
  }

  /*  styles for left and right pages when printing */
  @page :left {
    margin-left: 20px;
  }

  @page :right {
    margin-right: 20px;
  }
}

/* styles.css (or your preferred stylesheet file) */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 1s;
}

.otpInputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid #56698b;
  background-color: #2424cc1a;
  border-radius: 0.625rem;
}

ul.react-paginate {
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0 5rem;
}

ul.react-paginate li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  margin-right: 1rem;
  cursor: pointer;
}
ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  border-color: transparent;
}
ul.react-paginate li.selected a {
  background-color: #0366d6;
  border-color: transparent;
  color: white;
  min-width: 32px;
}
ul.react-paginate li.disabled a {
  color: grey;
}
ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: default;
}

